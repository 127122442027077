<template>
  <v-footer id="core-footer" height="82">
    <v-spacer />
    <span class="font-weight-light copyright">
      &copy;
      {{ (new Date()).getFullYear() }}
      <a
        href="https://www.autopassgroup.com/"
        target="_blank" class="orange--text"
      >AutoPass</a>
    </span>
  </v-footer>
</template>

<script>
export default {
  name: 'CoreFooter',
  data: () => ({})
}
</script>

<style>
#core-footer {
  z-index: 0;
}
</style>
